



























































































































































  import VirtualPetSelect from '@/components/modals/VirtualPetSelect.vue';
import Vue from "vue";
import Modal from '../../components/modals/Modal.vue';
import ModalService from '@/components/modals/services/ModalService.vue';

export default Vue.extend({
  name: "HomeVirtualPet",
  components: {Modal},
  data: () => {
    return {
      loaded: false,
      error: false,
      owner: false,
      admin: false,
      security: false,
      errorMessage: "",
      success: false,
      placeId: null,
      slug: null,
      petName: "",
      petURL: null,
      activatePet: false,
      petVoice: 0,
      behaviours: [],
      petFound: false,
      page: "update",
      petsURLS: [
      ['/assets/pets/dog/dog.wrl'],
      ['/assets/pets/cat/cat.wrl'],
      ['/assets/pets/alien/alien.wrl'],
      ['/assets/pets/bug/bug.wrl'],
      ['/assets/pets/bunny/bunny.wrl'],
      ],
      pets: ["dog", "cat", "alien", "bug", "bunny", ]
    }
  },
  methods: {
    async getPet() {
      try {
        this.loaded = true;
        const pet = await this.$http.get(`/place/virtual-pet/${this.placeId}`);
        if(pet.data.data.length >= 1){
          this.petFound = true;
          this.behaviours = JSON.parse(pet.data.data[0].pet_behaviours);
          this.petName = pet.data.data[0].pet_name;
          this.petURL = pet.data.data[0].pet_avatar_url;
          this.activatePet = pet.data.data[0].active;
          this.petVoice = pet.data.data[0].pet_voice_id;
        } else {
          this.addPet();
        }
      } catch(e) {
        console.error(e);
      }
    },
    async updatePet(){
      try{
        if(!this.petName){
          this.success = false;
          this.errorMessage = "Please enter Pet's Nickname."
          return this.error = true;
        }
        const updatePet = await this.$http.post(`/place/virtual-pet/update/${this.placeId}`, {
          name: this.petName,
          avatar: this.petURL,
          active: this.activatePet,
          voice: this.petVoice,
          behaviours: JSON.stringify(this.behaviours),
        });
        if(updatePet.data.success){
          this.error = false;
          this.errorMessage = '';
          this.success = true;
          this.getPet();
        } else {
          this.error = true;
          this.errorMessage = updatePet.data.error;
          this.success = false;
          this.getPet();
        }
      } catch(e) {
        console.log(e);
      }
    },
    async addPet(){
      await this.$http.post(`/place/virtual-pet/add/${this.placeId}`)
        .then((res) => {
          if(res){
            this.getPet();
          }
      })
    },
    petHelp() {
      window.open("/#/virtualpethelp/", "targetWindow", "width=1000px,height=700px,location=0,menubar=0,status=0,scrollbars=0");
    },
    selectPet(){
      this.page = 'select';
    },
    setPet(data){
      this.petURL = String(this.petsURLS[this.pets.indexOf(data)]);
      this.page = 'update';
    },
    async getAccessLevel() {
      const access = await this.$http.get("/member/getadminlevel");
      if(access.data.accessLevel.includes("security")){
        this.security = true;
      }
      if(access.data.accessLevel.includes("admin")){
        this.admin = true;
      }
    },
    async checkOwner() {
      const homeResponse = await this.$http.get("/home");
      if(homeResponse.data.homeData.id === parseInt(this.placeId)){
        this.owner = true;
      }
    },
  },
  created() {
    this.placeId = this.$route.params.place_id;
    this.slug = this.$store.data.place.slug;
    this.getPet()
  },
  mounted(){
    this.getAccessLevel();
    this.checkOwner();
  }
});
